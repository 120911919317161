
import { Vue, Component } from 'vue-property-decorator';
import JtAvatarUpload from "@/components/JtAvatarUpload/index.vue";
import { Loading } from "element-ui";
import {
  bindChannelPid,
  loadInviteCode,
  loadTaobaoAuth,
  loadTaobaoConfig, loadThirdPlatformConfig,
  removeTaobaoAuth,
  taoBaoAppAuth,
  updateConfig, updateThirdConfig
} from "@/api/taoke/tkTaobaoConfig";
import { removeApp } from "@/api/system/appConfig";
import {loadJDConfig, updateJDConfig} from "@/api/taoke/tkTaokeConfig";

@Component({
  name: 'jdConfig'
})
export default class extends Vue {
  configForm:{
    jdServerUrl: string
    jdAppId: string
    jdUnionId: string
    jdAppKey: string
    jdAppSecret: string
    jdAuthKey: string
    jdAuthExpir: string
  } = {
    jdServerUrl: '',
    jdAppId: '',
    jdUnionId: '',
    jdAppKey: '',
    jdAppSecret: '',
    jdAuthKey: '',
    jdAuthExpir: ''
  }
  isEdit = false

  mounted(){
    this.loadJDConfig();
  }

  loadJDConfig(){
    loadJDConfig().then(res => {
      this.configForm = res.data
    })
  }
  handleSubmitEdit(){
    updateJDConfig(this.configForm).then(res => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.isEdit = false
    })
  }
  handleEdit(isEdit:boolean){
    this.isEdit = isEdit
  }
}
